<template>
    <div class="col-12">
    <div class="card">
        <h5>Color Theme</h5>
        <p>This sets the colors for chat and coach profiles.</p>
        <SelectButton v-model="selectButtonValue" :options="selectButtonValues" optionLabel="name" v-on:change="onSelectChange" />
    </div>
    </div>
</template>

<script>
import UserService from '../service/UserService';
export default {
    userService: null,
    data() {
        return {
            selectButtonValue: null,
            selectButtonValues: [
                {name: 'blues', code: 'blue'},
                {name: 'greens', code: 'green'},
            ],
        }

    },
    created() {
        this.userService = new UserService();
    },
    mounted() {
        // this.userService.logoutUser().then((data) => this.$router.push("/login"));
        var theme = this.userService.getColorsCookie();
        this.selectButtonValue = {"name": theme + "s", "code": theme};
    },
    methods: {
        onSelectChange(event) {
            this.userService.setColors(event.value.code);
        }
    }
}
</script>
